import React, { useState } from 'react';
import './DailyReport.css';  // Импорт стилей для DailyReport
function DailyReport({ data, onChange, days }) {
  const [currentDay, setCurrentDay] = useState(days[0]);

  const handleChange = (rowIndex, change) => {
    onChange(rowIndex, currentDay, change);
  };

  const handleDayChange = (direction) => {
    const currentIndex = days.indexOf(currentDay);
    if (direction === 'prev' && currentIndex > 0) {
      setCurrentDay(days[currentIndex - 1]);
    } else if (direction === 'next' && currentIndex < days.length - 1) {
      setCurrentDay(days[currentIndex + 1]);
    }
  };

  return (
    <div className="daily-report">
      <div className="day-selector">
        <button onClick={() => handleDayChange('prev')} disabled={currentDay === days[0]}>
          Назад
        </button>
        <span>{currentDay}</span>
        <button onClick={() => handleDayChange('next')} disabled={currentDay === days[days.length - 1]}>
          Вперед
        </button>
      </div>
      <table border="1">
        <thead>
          <tr>
            <th>Исследование</th>
            <th>Результат</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.name}</td>
              <td>
                <div className="editable-cell">
                  <button onClick={() => handleChange(rowIndex, -1)}>-</button>
                  <input type="number" value={row[currentDay] || ''} readOnly />
                  <button onClick={() => handleChange(rowIndex, 1)}>+</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DailyReport;
