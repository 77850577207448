import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import './TotalReport.css';  // Импорт стилей для TotalReport

function TotalReport({ data, days, setData, initialData }) {
  // Функция для подсчета общего результата по строкам (по каждому исследованию)
  const calculateRowTotal = (row) => {
    return days.reduce((sum, day) => sum + (row[day] || 0), 0);
  };

  // Функция для подсчета общего результата по колонкам (по каждому дню)
  const calculateColumnTotal = (day) => {
    return data.reduce((sum, row) => sum + (row[day] || 0), 0);
  };

  // Функция для экспорта данных в Excel с использованием ExcelJS
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Total Results');

    // Установка ширины столбцов
    worksheet.columns = [
      { header: 'Исследование', key: 'name', width: 20 },
      ...days.map((day, index) => ({
        header: day,
        key: `day_${index}`,
        width: 10,
      })),
      { header: 'Итого', key: 'total', width: 10 },
    ];

    // Добавление данных
    data.forEach((row) => {
      const rowValues = [row.name];
      days.forEach((day) => {
        rowValues.push(row[day] || 0);
      });
      rowValues.push(calculateRowTotal(row));
      worksheet.addRow(rowValues);
    });

    // Добавление итоговой строки
    const totalRow = ['Итого'];
    days.forEach((day) => {
      totalRow.push(calculateColumnTotal(day));
    });
    totalRow.push(days.reduce((sum, day) => sum + calculateColumnTotal(day), 0));
    worksheet.addRow(totalRow);

    // Добавление границ для всех ячеек
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: 'medium' },
          left: { style: 'medium' },
          bottom: { style: 'medium' },
          right: { style: 'medium' },
        };
      });
    });

    // Формирование имени файла с добавлением первой и последней даты отчетного периода
    const startDate = days[0].replace(/\./g, '-');
    const endDate = days[days.length - 1].replace(/\./g, '-');
    const fileName = `недельный_отчет_${startDate}_to_${endDate}.xlsx`;

    // Сохранение файла
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };

  // Функция для сброса данных с подтверждением
  const resetData = () => {
    if (window.confirm('Вы уверены, что хотите сбросить все данные? Это действие не может быть отменено.')) {
      setData(initialData);
      localStorage.removeItem('reportData');
    }
  };

  return (
    <div className="total-report">
      <h2>Общий результат по дням</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Исследование</th>
            {days.map(day => (
              <th key={day}>{day}</th>
            ))}
            <th>Итого</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.name}</td>
              {days.map(day => (
                <td key={day}>{row[day] || 0}</td>
              ))}
              <td>{calculateRowTotal(row)}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Итого</strong></td>
            {days.map(day => (
              <td key={day}><strong>{calculateColumnTotal(day)}</strong></td>
            ))}
            <td><strong>{days.reduce((sum, day) => sum + calculateColumnTotal(day), 0)}</strong></td>
          </tr>
        </tbody>
      </table>
      <button onClick={exportToExcel}>Экспорт в Excel</button>
      <button onClick={resetData} style={{ marginLeft: '10px', backgroundColor: 'red', color: 'white' }}>
        Сброс данных
      </button>
    </div>
  );
}

export default TotalReport;
