import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import DailyReport from './DailyReport';
import TotalReport from './TotalReport';
import './App.css';

// Функция для получения массива дат с понедельника по пятницу текущей недели в формате DD.MM.YYYY
const getCurrentWeekDays = () => {
  const today = new Date();
  const firstDayOfWeek = today.getDate() - today.getDay() + 1; // Начало недели (понедельник)
  const days = [];

  for (let i = 0; i < 5; i++) { // С понедельника по пятницу
    const day = new Date(today.setDate(firstDayOfWeek + i));
    const formattedDay = day.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }); // Формат DD.MM.YYYY
    days.push(formattedDay);
  }

  return days;
};

// Инициализация данных только с именем исследования, результаты будут добавлены позже
const initialData = [
  { name: 'УЗДГС' },
  { name: 'ЭХО КГ' },
  { name: 'ОБП' },
  { name: 'Почки' },
  { name: 'Щит.железа' },
  { name: 'Плевр.полость' },
  { name: 'Простата' },
  { name: 'Мочевой пузырь' },
  { name: 'Молочная железа' },
  { name: 'Л/узлы' },
  { name: 'М/ткани' },
  { name: 'Слюнные' },
  { name: 'БЦА' },
  { name: 'Мошонка' },
];

function App() {
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem('reportData');
    const daysOfWeek = getCurrentWeekDays();

    // Если данные уже сохранены в localStorage, используем их
    if (savedData) {
      return JSON.parse(savedData);
    }

    // Инициализация данных с пустыми значениями для текущей недели
    return initialData.map(item => {
      const newItem = { ...item };
      daysOfWeek.forEach(day => {
        newItem[day] = 0; // Инициализация результатов по дням недели
      });
      return newItem;
    });
  });

  useEffect(() => {
    localStorage.setItem('reportData', JSON.stringify(data));
  }, [data]);

  const handleChange = (rowIndex, day, change) => {
    const newData = [...data];
    newData[rowIndex][day] = (newData[rowIndex][day] || 0) + change;
    setData(newData);
  };

  const daysOfWeek = getCurrentWeekDays();

  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Ежедневный отчет</Link>
            </li>
            <li>
              <Link to="/total">Общий результат</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route
            path="/"
            element={<DailyReport data={data} onChange={handleChange} days={daysOfWeek} />}
          />
          <Route
            path="/total"
            element={<TotalReport data={data} days={daysOfWeek} setData={setData} initialData={initialData} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
